import CategoryIcon from "@mui/icons-material/Category";
import EmailIcon from "@mui/icons-material/Email";
import TagIcon from "@mui/icons-material/Tag";
import List from "@mui/material/List";
import { useMemo } from "react";

import { usePermissions } from "src/hooks/usePermissions";
import { SpannerIcon } from "src/icons/spanner";
import { TradePersonIcon } from "src/icons/tradeperson";
import { areCustomerBillsEnabled } from "src/utils/feature-flags";

import { Cog as CogIcon } from "../../icons/cog";
import { User as UserIcon } from "../../icons/user";
import type { NavItemType } from "../types";
import NavItem from "./NavItem";
import { Person } from "@mui/icons-material";
import { useIsCompaniesUIEnabled } from "src/hooks/flags/useCompaniesEnabled";

const items: NavItemType[] = [
  {
    href: "/jobs",
    icon: <SpannerIcon fontSize="small" />,
    title: "Jobs",
    permission: "JOBS",
    adminOnly: false,
  },
  ...(areCustomerBillsEnabled()
    ? [
        {
          href: "/bills",
          icon: <UserIcon fontSize="small" />,
          title: "Bills",
          permission: "BILLS",
          adminOnly: true,
        },
      ]
    : []),
  {
    href: "/tradespeople",
    icon: <TradePersonIcon fontSize="small" />,
    title: "Tradespeople",
    permission: "TRADESPEOPLE",
    adminOnly: false,
  },
  {
    href: "/managed-rates",
    icon: <CogIcon fontSize="small" />,
    title: "Managed Rates",
    permission: "CONFIGURATIONS",
    adminOnly: true,
  },
  {
    href: "/tags",
    icon: <TagIcon fontSize="small" />,
    title: "Tags",
    permission: "CONFIGURATIONS",
    adminOnly: true,
  },
  {
    href: "/products",
    icon: <CategoryIcon fontSize="small" />,
    title: "Products",
    permission: "CONFIGURATIONS",
    adminOnly: true,
  },
  {
    href: "/companies",
    icon: <Person fontSize="small" />,
    title: "Companies",
    permission: "TRADESPEOPLE",
    adminOnly: false,
  },
  {
    href: "/comms/templates",
    icon: <EmailIcon fontSize="small" />,
    title: "Comm Templates",
    permission: "CONFIGURATIONS",
    adminOnly: true,
  },
];

export function MenuList() {
  const { permissions } = usePermissions();
  const companiesEnabled = useIsCompaniesUIEnabled();

  const navItems = useMemo(() => {
    const allowedItems: NavItemType[] = [];

    for (const item of items) {
      if (item.title === "Companies") {
        if (!companiesEnabled) {
          continue;
        }
      }

      if (permissions.includes(item.permission)) {
        allowedItems.push(item);
      }
    }

    return allowedItems;
  }, [companiesEnabled, permissions]);

  return (
    <>
      <List>
        {navItems.map((item, i) => (
          <NavItem level={1} item={item} key={i}></NavItem>
        ))}
      </List>
    </>
  );
}
